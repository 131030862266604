import Page from "@jumbo/shared/Page/Page";

import BlogRouteMiddleware from "./middleware/auth/blogValidRoute";
import ListBlog from "app/pages/BlogtManagement/ListBlog";
import AddBlog from "app/pages/BlogtManagement/AddBlog";
import EditBlog from "app/pages/BlogtManagement/EditBlog";



const blogRoutes = [
  {
    middleware: [
      {
        element: BlogRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/blog",
        element: <Page component={ListBlog} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/addblog",
        element: <Page component={AddBlog} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/editblog/:id",
        element: <Page component={EditBlog} layout={"vertical-default"} />,
      },
  
    ],
  },



];

export default blogRoutes;
