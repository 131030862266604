import {
  ALL_CLIENTS_FAIL,
  ALL_CLIENTS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_CLIENT_FAIL,
  LOAD_CLIENT_REQUEST,
  LOAD_CLIENT_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
} from "app/utils/constants/clientConstants";

const INIT_STATE = {
  loading: false,
  isAuthenticated: false,
  error: null,
  allClient: [],
  TotalPage: null,
};

export const clientReducer = (state = { client: INIT_STATE }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOAD_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
    case LOAD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        client: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        client: null,
        error: action.payload,
      };
    case LOAD_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        client: null,
        error: action.payload,
      };
    case ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        allClient: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_CLIENTS_FAIL:
      return {
        ...state,
        allClients: [],
        error: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        client: null,
        allClients: [],
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
