import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ClientRouteMiddleware = ({ fallbackPath }) => {
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  if (
    permissions.client_view === true ||
    permissions.client_edit === true ||
    permissions.client_create === true
  ) {
    return <Outlet />;
  }
  else {
    return <Navigate to={fallbackPath} />;
  }
};

export default ClientRouteMiddleware;
