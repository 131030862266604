import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { roleReducer } from "./roleReducer";
import { clientReducer } from "./clientReducer";
import { videoReducer } from "./videoReducer";
import { blogReducer } from "./blogReducer";
import { careerReducer } from "./careerReducer";




const exportReducers = (history) => {
  return combineReducers({
    userReducer: userReducer,
    roleReducer: roleReducer,
    clientReducer: clientReducer,
    videoReducer: videoReducer,
    blogReducer: blogReducer,
    careerReducer: careerReducer,
    
  });
};

export default exportReducers;
