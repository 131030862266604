import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { UpdateSingleVideo } from "app/services/apis/updateVideo";
import { LinearProgress } from "@mui/material";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
const EditVideoPage = ({ openView, setOpenView, data }) => {
  const { id } = useParams();
  const [oldVideo, setOldVideo] = useState(data);
  const [newVideos, setNewVideos] = useState([]);
  const [deleteVideos, setDeleteVideos] = useState([]);
  console.log(deleteVideos, "deleteVideos");
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const handleRemoveVideo = (videoToRemove) => {
    const updatedData = oldVideo.filter((video) => video !== videoToRemove);
    setDeleteVideos((prevDeletedVideos) => [
      ...prevDeletedVideos,
      videoToRemove,
    ]);
    setOldVideo(updatedData);
  };

  const handleRemoveNewVideo = (videoToRemove) => {
    const updatedData = newVideos.filter((video) => video !== videoToRemove);
    setNewVideos(updatedData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "video/*",
    onDrop: (acceptedFiles) => {
      setNewVideos((prevVideos) => [
        ...prevVideos,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(
    () => () => {
      newVideos.forEach((video) => URL.revokeObjectURL(video.preview));
    },
    [newVideos]
  );

  const handleUploadVideo = async () => {
    setLoading(true);

    // const formData = new FormData();
    // newVideos.forEach((video) => {
    //   formData.append(`videos`, video);
    // });
    // formData.append(`deleteVideos`, JSON.stringify([...deleteVideos]));
    //   const response = await UpdateSingleVideo(formData, id);
    const s3 = new S3Client({
      region: "eu-north-1",
      credentials: {
        accessKeyId: "AKIATCKAPDJAZGBB4LFY",
        secretAccessKey: "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",
      },
    });

    async function uploadFile(file) {
      const params = {
        Bucket: "jetafx-video-storage",
        Key: file.name,
        Body: file,
      };

      try {
        const data = await s3.send(new PutObjectCommand(params));
        const location = `https://jetafx-video-storage.s3.amazonaws.com/${encodeURIComponent(
          file.name
        )}`;
        return location;
      } catch (err) {
        console.error(err);
      }
    }

    const uploadedLocations = [];
    for (const file of newVideos) {
      const location = await uploadFile(file);
      if (location) {
        uploadedLocations.push(location);
      }
    }
    const updateVideo = {
      videos: uploadedLocations,
      deleteVideos: deleteVideos,
    };

    const response = await UpdateSingleVideo(updateVideo, id);

    // Reset progress and loading state

    setLoading(false);
    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Video Edited Successfully",
        text: "",
      });
      setOpenView(false);
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: response?.message,
        text: "",
      });
    }
  };


  useEffect(() => {
    const timer = setInterval(() => {
      // Increase progress value by 1 every 6 seconds until it reaches 10
      if (progressValue < 10) {
        setProgressValue(prevProgress => prevProgress + 1);
      }
    }, 6000); // 6 seconds

    return () => clearInterval(timer);
  }, [progressValue]);
  return (
    <Dialog
      open={openView}
      onClose={() => setOpenView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        style={{ backgroundColor: "#7352C7", color: "white" }}
        id="alert-dialog-title"
      >
        Selected Videos ({oldVideo.length})
      </DialogTitle>

      <DialogContent
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{ mb: 3.75 }}
      >
        <ImageListItem>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ marginTop: "10px", width: "95px" }}
          >
            <input {...getInputProps()} />
            <Button size="small" variant="contained">
              Add Video
            </Button>
          </div>
        </ImageListItem>
        <ImageList
          sx={{ width: "100%", maxHeight: 250 }}
          cols={3}
          rowHeight={110}
        >
          {oldVideo?.map((video) => (
            <ImageListItem key={video}>
              <HighlightOffIcon
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  color: "red",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                }}
                onClick={() => handleRemoveVideo(video)}
              />
              <video
                src={`${video}`}
                controls
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </ImageListItem>
          ))}
          {newVideos?.map((video) => (
            <ImageListItem key={video.name}>
              <HighlightOffIcon
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  color: "red",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                }}
                onClick={() => handleRemoveNewVideo(video)}
              />
              <video
                src={video.preview}
                controls
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          type="submit"
          variant="contained"
          onClick={() => handleUploadVideo(newVideos)}
        >
          Update Video
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setOpenView(false)}
        >
          Close
        </Button>
      </DialogActions>
      {loading && (
        <LinearProgress variant="determinate" value={progressValue} />
      )}
    </Dialog>
  );
};

export default EditVideoPage;
