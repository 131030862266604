import {
  ALL_CAREERS_REQUEST,
  ALL_CAREERS_SUCCESS,
  CLEAR_ERRORS,
  ALL_CAREERS_FAIL,
  LOAD_CAREER_REQUEST,
  LOAD_CAREER_SUCCESS,
} from "app/utils/constants/careerConstants";

import axios from "axios";

export const loadClient = () => async (dispatch) => {
  try {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    dispatch({ type: LOAD_CAREER_REQUEST });
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/profile/list-us-profile`,
      config
    );

    dispatch({ type: LOAD_CAREER_SUCCESS, payload: data.data.result });
  } catch (error) {
    dispatch({
      type: ALL_CAREERS_FAIL,
      payload: error.response.data.description,
    });
  }
};

export const getAllCareer =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      dispatch({ type: ALL_CAREERS_REQUEST });
      const body = {
        filters: {},
        searchFields: {
          string: ["job_title", "job_type", "address", "location"],
          numbers: ["salary"],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/career/list-career?${urlParams.toString()}`,
        body,
        config
      );

      console.log(data, "data");
      dispatch({
        type: ALL_CAREERS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CAREERS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
