import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./user.css";

import SettingsIcon from "@mui/icons-material/Settings";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { displayDateFun } from "app/utils/constants/functions";
import { getAllRoles } from "app/redux/actions/roleAction";
export default function ListRoleTable({
  searchTerm,
  page,
  setPage,
  sort,
  sortBy,
  setSort,
  setSortBy,
}) {
  const { allRoles, TotalPage } = useSelector((state) => state.roleReducer);
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={sortBy === "role_name"}
                direction={sort}
                onClick={() => handleSort("role_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Role Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={sortBy === "created_by.first_name"}
                direction={sort}
                onClick={() => handleSort("created_by.first_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Created By
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={sortBy === "status"}
                direction={sort}
                onClick={() => handleSort("status")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Created Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Updated Date
            </TableCell>
            {permissions?.role_edit == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Configure
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {allRoles?.map((row, i) => (
            <TableRow key={i}>
        
              <TableCell sx={{ textAlign: "left" }}>{row.role_name}</TableCell>
              <TableCell sx={{ textTransform: "capitalize" }}>
                {row?.created_by?.first_name}{" "}
                {row?.created_by?.last_name}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.status === false ? "Inactive" : "Active"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.created_at)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.updated_at ? displayDateFun(row.updated_at) : "N/A"}
              </TableCell>
              {permissions.role_edit == true && (
                <TableCell sx={{ textAlign: "left", pl: 5 }}>
                  <SettingsIcon
                    sx={{ "&:hover": { cursor: "pointer", color: "black" } }}
                    onClick={() => {
                      navigate("/dashboard/editrole", { state: row });
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
  );
}
