import Div from "@jumbo/shared/Div/Div";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { Form, Formik } from "formik";
import Chip from "@mui/material/Chip";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import styled from "@mui/material/styles/styled";
import DropMultiImage from "app/components/ImageUpload";
import { addBlog } from "app/services/apis/addBlog";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.1),
  borderRadius: "4px",
  display: "inline-block",
  padding: theme.spacing(0.1),
}));

export default function AddBlog() {
  const navigate = useNavigate();

  const [isSubmitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [files, setFiles] = useState([]);
  const addTagsItem = (event) => {
    if (tags.trim() === "") {
      return;
    }
    const newItem = tagsData.concat(tags);
    setTagsData(newItem);
    setTags("");
    event.preventDefault();
  };

  const handleTagsDelete = (indexToDelete) => {
    console.log("Deleting tag at index:", indexToDelete);
    setTagsData((prevTags) =>
      prevTags.filter((_, index) => index !== indexToDelete)
    );
  };
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const client = {
    title: "",
    description: "",
    blog_images: [],
    tags: [],
  };
  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
  });

  const onClientSave = async (values) => {
    setSubmitting(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`blog_images`, file);
    });
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("tags", JSON.stringify([...tagsData]));

    try {
      const data = await addBlog(formData);
      if (data?.status == 201) {
        Swal.fire({
          icon: "success",
          title: "Blog Added Successfully",
          text: "",
        });
        navigate("/dashboard/blog");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Add New Blog</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={client}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              {console.log(values, "values")}
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={4}>
                    <FormTextField1 name="title" label="Title" />
                  </Grid>
                  <Grid item xs={8}>
                    <FormTextField1 name="description" label="Description" />
                  </Grid>
                </Grid>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={3.11} mt={1}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      label="Add Tags..."
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={1} mt={3}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addTagsItem}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "80px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {tagsData?.map((data, Index) => (
                        <ListItem key={Index}>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={data}
                            onDelete={() => handleTagsDelete(Index)}
                          />
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>{" "}
                <Grid container rowSpacing={3} columnSpacing={3} mt={5}>
                  <Grid item xs={4}>
                    <Typography variant="body1">Blog Images :-</Typography>
                    <DropMultiImage setImages={setFiles} images={files} />
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/client");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
