import {
  ALL_VIDEOS_FAIL,
  ALL_VIDEOS_REQUEST,
  ALL_VIDEOS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_VIDEOS_FAIL,
  LOAD_VIDEOS_REQUEST,
  LOAD_VIDEOS_SUCCESS,
} from "app/utils/constants/videoConstants";

import axios from "axios";
    

export const loadClient = () => async (dispatch) => {
  try {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    dispatch({ type: LOAD_VIDEOS_REQUEST });
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/profile/list-us-profile`,
      config
    );
    // console.log(data);
    dispatch({ type: LOAD_VIDEOS_SUCCESS, payload: data.data.result });
  } catch (error) {
    dispatch({
      type: LOAD_VIDEOS_FAIL,
      payload: error.response.data.description,
    });
  }
};



export const getAllVideos =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      dispatch({ type: ALL_VIDEOS_REQUEST });
      const body = {
        filters: {},
        searchFields: {
          string: ["title","language"],
          numbers: ["created_by"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/video/list-video?${urlParams.toString()}`,
        body,
        config
      );

   console.log(data,'data');
      dispatch({
        type: ALL_VIDEOS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_VIDEOS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
