import {
  ALL_VIDEOS_SUCCESS,
  LOAD_VIDEOS_FAIL,
  LOAD_VIDEOS_REQUEST,
} from "app/utils/constants/videoConstants";

const INIT_STATE = {
  loading: false,
  error: null,
  allvideo: [],
  TotalPage:null
};

export const videoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_VIDEOS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        allvideo: action.payload.data,
        TotalPage:action.payload.totalPage,
      };

    case LOAD_VIDEOS_FAIL:
      return {
        ...state,
        loading: false,
        allvideo: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
