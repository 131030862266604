import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const CareerRouteMiddleware = ({ fallbackPath }) => {
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  if (
    permissions.career_view === true ||
    permissions.career_edit === true ||
    permissions.career_create === true
  ) {
    return <Outlet />;
  }
  else {
    return <Navigate to={fallbackPath} />;
  }
};

export default CareerRouteMiddleware;
