import React, { useEffect, useState } from "react";
import { Grid, ImageList, ImageListItem, Typography } from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import EditVideoPage from "./editVideo";
import FormTextField1 from "app/components/InputField/FormTextField1";
import Div from "@jumbo/shared/Div";
import ListOptions1 from "app/components/Dropdown/ListOptions1";
import { languageList } from "app/utils/constants/dropdowns";
import { ListSingleVideo, updateVideo } from "app/services/apis/updateVideo";

const EditVideo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id, "dskjgjdsbg");
  const [openView, setOpenView] = useState(false);
  const [files, setFiles] = useState([]);
  const [initialValues, setInitialValues] = useState({
    title: "",
    language: "",
    duration: "",
    description: "",
    module_no: "",
    videos: [],
  });

  const getVideoDetail = async () => {
    try {
      let res = await ListSingleVideo(id);
      let data = res.data.result;
      setInitialValues({
        title: data.title,
        language: data.language,
        duration: data.duration,
        module_no: data.module_no,
        description: data.description,
        videos: data.videos || [],
      });
      setFiles(data.videos || []);
    } catch (error) {
      //   showAlert("error", error.response.data.message);
    }
  };

  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    language: yup.string("Enter Language").required("Language is required"),
    duration: yup.string("Enter Duration").required("Duration is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
    module_no: yup.string("Enter Module No").required("Module No is required"),
  });

  const handleEventEdit = async (value) => {
    const uploadedData = {
      title: value.title,
      language: value.language,
      duration: value.duration,
      description: value.description,
      module_no: value.module_no,
    };
    const data = await updateVideo(uploadedData, id);
    if (data?.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Video Edited Successfully",
        text: "",
      });
      navigate("/dashboard/video");
    } else {
      Swal.fire({
        icon: "error",
        title: data?.message,
        text: "",
      });
    }
  };

  useEffect(() => {
    getVideoDetail();
  }, [openView]);

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        Edit Video
      </Typography>

      <Formik
        key={JSON.stringify(initialValues)}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          validationSchema
            .validate(data, { abortEarly: false })
            .then(() => {
              handleEventEdit(data);
              setSubmitting(false);
            })
            .catch((validationErrors) => {
              console.error("Validation Errors:", validationErrors);
              setSubmitting(false);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Div sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField1
                    name="title"
                    label="Title"
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                    value={values?.title}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FormTextField1
                    name="module_no"
                    type="number"
                    label="Module"
                    onChange={(e) => setFieldValue("module_no", e.target.value)}
                    value={values.module_no}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ListOptions1
                    name="language"
                    label="Language"
                    options={languageList}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField1
                    name="duration"
                    label="Duration"
                    onChange={(e) => setFieldValue("duration", e.target.value)}
                    value={values.duration}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormTextField1
                    multiline
                    // rows={2}
                    name="description"
                    label="Description"
                  />
                </Grid>
              </Grid>
            </Div>

            <Grid container rowSpacing={3} columnSpacing={3} marginTop={1}>
              <Grid item xs={9}>
                <Typography variant="body1">Videos :-</Typography>
                <Button
                  size="small"
                  variant="contained"
                  onClick={setOpenView}
                  style={{ marginTop: "8px" }}
                >
                  <ModeEditIcon />
                </Button>
                {openView && (
                  <EditVideoPage
                    openView={openView}
                    setOpenView={setOpenView}
                    data={files}
                  />
                )}

                <ImageList
                  sx={{ width: "100%", maxHeight: 250 }}
                  cols={4}
                  rowHeight={110}
                >
                  {files.map((file) => (
                    <ImageListItem key={file}>
                      <video
                        src={file}
                        controls
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} mt={10}>
              <Grid item xs={6} textAlign="right">
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/event");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default EditVideo;
