import Page from "@jumbo/shared/Page/Page";
import ClientRouteMiddleware from "./middleware/auth/clientValidRoute";
import AddClient from "app/pages/ClientManagement/AddClient";
import ListClient from "app/pages/ClientManagement/ListClient";


const clientManagementRoutes = [
  {
    middleware: [
      {
        element: ClientRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/client",
        element: <Page component={ListClient} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/addclient",
        element: <Page component={AddClient} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/editclient",
        element: <Page component={AddClient} layout={"vertical-default"} />,
      },
    ],
  },

];

export default clientManagementRoutes;
