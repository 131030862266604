import {
  ALL_BLOGS_FAIL,
  ALL_BLOGS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_BLOG_FAIL,
  LOAD_BLOG_REQUEST,
  LOAD_BLOG_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
} from "app/utils/constants/blogConstants";

const INIT_STATE = {
  loading: false,
  isAuthenticated: false,
  error: null,
  allBlog: [],
  TotalPage: null,
};

export const blogReducer = (state = { client: INIT_STATE }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOAD_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
    case LOAD_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        blog: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        blog: null,
        error: action.payload,
      };
    case LOAD_BLOG_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        blog: null,
        error: action.payload,
      };
    case ALL_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        allBlog: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_BLOGS_FAIL:
      return {
        ...state,
        allBlogs: [],
        error: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        blog: null,
        allBlogs: [],
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
