import Page from "@jumbo/shared/Page";

import VideoRouteMiddleware from "./middleware/auth/videoValidRoute";
import ListVideo from "app/pages/Video/ListVideo";
import AddVideo from "app/pages/Video/AddVideo";
import EditVideo from "app/pages/Video/EditVideo";


export const videoManagementRoutes = [
  {
    middleware: [
      {
        element: VideoRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/video",
        element: <Page component={ListVideo} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/addvideo",
        element: <Page component={AddVideo} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/editvideo/:id",
        element: <Page component={EditVideo} layout={"vertical-default"} />,
      },
    ],
  },
];
