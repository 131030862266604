import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  Grid,
  ImageList,
  ImageListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import Chip from "@mui/material/Chip";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import styled from "@mui/material/styles/styled";
import { addBlog, getByIddBlog } from "app/services/apis/addBlog";
import EditBlogImage from "./EditImages";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { updateClientDetailsAdmin } from "app/services/apis/updateClientDetailsAdmin";
import { updateBlog } from "app/services/apis/updateblog";
import axios from "axios";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.1),
  borderRadius: "4px",
  display: "inline-block",
  padding: theme.spacing(0.1),
}));

export default function EditBlog() {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id, "id");
  const [isSubmitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [files, setFiles] = useState([]);
  const [openView, setOpenView] = useState(false);
  const addTagsItem = (event) => {
    if (tags.trim() === "") {
      return;
    }
    const newItem = tagsData.concat(tags);
    setTagsData(newItem);
    setTags("");
    event.preventDefault();
  };

  console.log(files, "files");
  const handleTagsDelete = (indexToDelete) => {
    console.log("Deleting tag at index:", indexToDelete);
    setTagsData((prevTags) =>
      prevTags.filter((_, index) => index !== indexToDelete)
    );
  };
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    blog_images: [],
    tags: [],
  });
  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
    tags: yup.array("Tags").nullable().required("Tags is required"),
  });

  const getBanquetDetails = async () => {
    try {
      const res = await getByIddBlog(id);
      console.log(res.data, "resresres");
      let data = res?.data?.result;
      setInitialValues({
        title: data.title,
        description: data.description,
        blog_images: [],
        tags: [],
      });

      setTagsData(data.tags || []);
      setFiles(data.blog_images || []);
    } catch (error) {
      console.log("error", error.response.data.message);
    }
  };
  const onClientSave = async (values) => {
    setSubmitting(true);
    // if (pathname == "/dashboard/editblog") {
    //   const data = await updateClientDetailsAdmin({
    //     ...values,
    //     id: state?._id,
    //   });
    //   if (data?.status == 200) {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Blog Edited Successfully",
    //       text: "",
    //     });
    //     navigate("/dashboard/blog");
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       title: data?.message,
    //       text: "",
    //     });
    //   }
    // } else {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`blog_images`, file);
    });
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("tags", JSON.stringify([...tagsData]));

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/blog/update-blog?id=${id}`,
      formData,
      config
    );
    if (data?.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Blog Updated Successfully",
        text: "",
      });
      navigate("/dashboard/blog");
    } else {
      Swal.fire({
        icon: "error",
        title: data?.data?.message,
        text: "",
      });
    }
    // }
    setSubmitting(false);
  };

  useEffect(() => {
    getBanquetDetails();
  }, [openView]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Edit Blog</Typography>
      <Div>
        <Formik
          key={JSON.stringify(initialValues)}
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              {console.log(values, "values")}
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={4}>
                    <FormTextField1 name="title" label="Title" />
                  </Grid>
                  <Grid item xs={8}>
                    <FormTextField1 name="description" label="Description" />
                  </Grid>
         
                </Grid>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={3.11} mt={1}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      label="Add Tags..."
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={1} mt={3}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addTagsItem}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "80px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {tagsData?.map((data, Index) => (
                        <ListItem key={Index}>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={data}
                            onDelete={() => handleTagsDelete(Index)}
                          />
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={9}>
                  <Typography variant="body1">Images :-</Typography>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={setOpenView}
                    style={{ marginTop: "8px" }}
                  >
                    <ModeEditIcon />
                  </Button>
                  {openView && (
                    <EditBlogImage
                      openView={openView}
                      setOpenView={setOpenView}
                      data={files}
                    />
                  )}

                  <ImageList
                    sx={{ width: "100%", maxHeight: 250 }}
                    cols={4}
                    rowHeight={110}
                  >
                    {files.map((file) => (
                      <ImageListItem key={file}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_IMAGE_PATH}/blog/${file}`}
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                          }}
                          alt=""
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/blog");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
