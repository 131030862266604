const AllApis = {
  dropdownList: {
    item_name: "/item-name-master/dropdown-item-name-master",
    item_code: "/item-code-master/dropdown-item-code-master",
    grade: "/grade-master/dropdown-grade-master",
    pallete: "/pallete-master/dropdown-pallete-master",
    unit: "/unit-master/dropdown-unit-master",
    roles:"/role/dropdown-roles-master"
  },

  //inventory
  raw_material: {
    addInventory: "/raw-material-inventory/add-raw-veneer",
    list: "/raw-material-inventory/fetch-raw-veneer",
    rejectQty: function (id) {
      return `/raw-material-inventory/reject-raw-veneer?id=${id}`;
    },
    addGrouping: "/raw-material-inventory/issue-for-grouping",
    history: "/raw-material-inventory/fetch-raw-veneer-history",
    addSmoking:"/raw-material-inventory/issue-for-smoking",
    addSingleSmoking:"/raw-material-inventory/issue-for-smoking-pattas",
    list_issued_smoking:"/raw-material-inventory/issued-for-smoking-raw-list",
    cancelSmoking: "/raw-material-inventory/cancel-smoking-raw",
    editRawMaterial: function(id){
      return `/raw-material-inventory/update-raw-veneer?id=${id}`
    }
  },
  other_goods: {
    addInventory: "/other-goods-inventory/add-other-goods",
    list: "/other-goods-inventory/list-other-goods",
    consumption: function (id) {
      return `/other-goods-inventory/update-other-goods?id=${id}`;
    },
    consumptionList: "/other-goods-inventory/list-other-goods-consumed",
  },
  grouping: {
    list: "/grouping/list-issued-for-grouping",
    cancelGrouping: "/raw-material-inventory/cancel-grouping",
  },
};
export default AllApis;
