// import Div from "@jumbo/shared/Div";
// import { Form, Formik } from "formik";
// import * as yup from "yup";
// import { useLocation, useNavigate } from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import { Button, Grid, Typography } from "@mui/material";

// import Swal from "sweetalert2";

// import { updateVideo } from "app/services/apis/updateVideo";
// import { addVideo } from "app/services/apis/addVideo";
// import { LoadingButton } from "@mui/lab";
// import { languageList } from "app/utils/constants/dropdowns";
// import FormTextField1 from "app/components/InputField/FormTextField1";
// import ListOptions1 from "app/components/Dropdown/ListOptions1";
// import DropMultiImage from "app/components/videoView";

// export default function AddVideo() {
//   const { state } = useLocation();
//   console.log("ffffffff", state);
//   const [isSubmitting, setSubmitting] = useState(false);

//   const [imageURL, setImageURL] = useState(
//     state?.image ? `${process.env.REACT_APP_URL}/${state.image}` : ""
//   );
//   const navigate = useNavigate();
//   const { pathname } = useLocation();
//   const [files, setFiles] = useState([]);

//   useEffect(
//     () => () => {
//       files.forEach((file) => URL.revokeObjectURL(file.preview));
//     },
//     [files]
//   );
//   console.log(files, "files");

//   const videoData = {
//     title: state?.title ? state?.title : "",
//     language: state?.language ? state?.language : "Select",
//     duration: state?.duration ? state?.duration : "",
//     description: state?.description ? state?.description : "",
//     module_no: state?.module_no ? state?.description : "",
//     videos: state?.videos ? state?.videos : [],
//   };
//   const validationSchema = yup.object({
//     title: yup.string("Enter Title").required("Title is required"),
//     duration: yup.string("Enter Duration").required("Duration is required"),
//     module_no: yup.string("Enter Module No").required("Module No is required"),
//     description: yup
//       .string("Enter Description")
//       .required("Description is required"),
//     // video: yup.string("Upload video").required("video is required"),
//     language: yup
//       .string()
//       .required("Language is required")
//       .test(
//         "language-not-select",
//         "Please select any  language",
//         (value) => value !== "Select"
//       ),
//   });
//   console.log(files, "sggggggggg");
//   const onUserSave = async (values) => {
//     console.log(values, "values");
//     setSubmitting(true);
//     const formdata = new FormData();
//     formdata.append("title", values.title);
//     formdata.append("language", values.language);
//     formdata.append("duration", values.duration);
//     formdata.append("description", values.description);
//     formdata.append("module_no", values.module_no);
//     //   files.forEach((val, i) => {
//     //     formdata.append('videos',{
//     //         type: "video/mp4",
//     //         name: val.image.filename,
//     //     })
//     // })
//     files.forEach((file) => {
//       formdata.append("videos", file.file); // Assuming `file` object contains the actual video file
//     });

//     if (pathname == "/dashboard/editvideo") {
//       const data = await updateVideo(formdata, state._id);
//       if (data?.status == 200) {
//         Swal.fire({
//           icon: "success",
//           title: "Video Edited Successfully",
//           text: "",
//         });
//         navigate("/dashboard/video");
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: data?.message,
//           text: "",
//         });
//       }
//     } else {
//
//     setSubmitting(false);
//   };

//   const handleFileChange = (event) => {
//     const selectedFiles = event.target.files;
//     if (selectedFiles) {
//       const updatedFiles = [...files, ...selectedFiles];
//       setFiles(updatedFiles);
//     }
//   };

//   const thumbsContainer = {
//     display: "flex",
//     marginTop: 5,
//     maxHeight: "250px",
//   };

//   const thumb = {
//     display: "flex",
//     borderRadius: 2,
//     justifyContent: "center",
//     alignContent: "center",
//     border: "1px solid #eaeaea",
//     marginBottom: 8,
//     marginRight: 8,
//     width: "70%",
//     height: "150px",
//     padding: 4,
//     boxSizing: "border-box",
//   };
//   return (
//     <Div sx={{ mt: -4 }}>
//       <Typography variant="h1">
//         {pathname == "/dashboard/addvideo" ? "Add New Video" : "Edit Video"}
//       </Typography>
//       <Div>
//         <Formik
//           validateOnChange={true}
//           initialValues={videoData}
//           enableReinitialize={true}
//           validationSchema={validationSchema}
//           onSubmit={onUserSave}
//         >
//           {({ setFieldValue, values, errors, touched }) => (
//             <Form noValidate autoComplete="off">
//               {console.log(values, "ffffffffffffffffffffffffff")}
//               <Div sx={{ mt: 4 }}>
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} sm={6} md={4}>
//                     <FormTextField1
//                       name="title"
//                       label="Title"
//                       onChange={(e) => {
//                         setFieldValue("title", e.target.value);
//                       }}
//                       value={values?.title}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={4} md={4}>
//                     <FormTextField1
//                       name="module_no"
//                       type="number"
//                       label="Module"
//                       onChange={(e) =>
//                         setFieldValue("module_no", e.target.value)
//                       }
//                       value={values.module_no}
//                     />
//                   </Grid>

//                   <Grid item xs={12} sm={6} md={4}>
//                     <ListOptions1
//                       name="language"
//                       label="Language"
//                       options={languageList}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6} md={4}>
//                     <FormTextField1
//                       name="duration"
//                       label="Duration"
//                       onChange={(e) =>
//                         setFieldValue("duration", e.target.value)
//                       }
//                       value={values.duration}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={12} md={4}>
//                     <FormTextField1
//                       multiline
//                       // rows={2}
//                       name="description"
//                       label="Description"
//                     />
//                   </Grid>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={6}>
//                   <Typography variant="body1">Video*</Typography>
//                   <DropMultiImage setImages={setFiles} images={files} />
//                 </Grid>

//                 {/* {files.length == 0 && (
//                   <aside style={thumbsContainer}>
//                     <div style={thumb}>

//                       <img
//                         src={`${values.videos}`}
//                         style={{
//                           display: "block",
//                           width: "100%",
//                           height: "100%",
//                         }}
//                         alt=""
//                       />
//                     </div>
//                   </aside>
//                 )} */}
//                 <Div
//                   sx={{
//                     width: "93.5%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     gap: 3,
//                     mt: 3,
//                   }}
//                 >
//                   <Button
//                     variant="outlined"
//                     onClick={() => {
//                       Swal.fire({
//                         title: "Are you sure you want to cancel?",
//                         icon: "warning",
//                         showCancelButton: true,
//                         confirmButtonText: "Yes",
//                         cancelButtonText: "No",
//                       }).then((result) => {
//                         if (result.isConfirmed) {
//                           navigate("/dashboard/user");
//                         }
//                       });
//                     }}
//                   >
//                     Cancel
//                   </Button>
//                   <LoadingButton
//                     variant="contained"
//                     type="submit"
//                     sx={{ width: "100px" }}
//                     loading={isSubmitting}
//                   >
//                     Save
//                   </LoadingButton>
//                 </Div>
//               </Div>
//             </Form>
//           )}
//         </Formik>
//       </Div>
//     </Div>
//   );
// }
import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { addVideo } from "app/services/apis/addVideo";
import DropMultiImage from "app/components/videoView";
import Div from "@jumbo/shared/Div";
import ListOptions1 from "app/components/Dropdown/ListOptions1";
import { languageList } from "app/utils/constants/dropdowns";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
const AddVideo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  var initialValues = {
    title: "",
    language: "",
    duration: "",
    description: "",
    module_no: "",
    videos: [],
  };

  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    language: yup.string("Enter Language").required("Language is required"),
    duration: yup.string("Enter Duration").required("Duration is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
    module_no: yup.string("Enter Module No").required("Module No is required"),
  });

  const [files, setFiles] = useState([]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleEventAdd = async (value) => {
    setLoading(true);

    // const formData = import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
    const s3 = new S3Client({
      region: "eu-north-1",
      credentials: {
        accessKeyId: "AKIATCKAPDJAZGBB4LFY",
        secretAccessKey: "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",
      },
    });

    async function uploadFile(file) {
      const params = {
        Bucket: "jetafx-video-storage",
        Key: file.name,
        Body: file,
      };

      try {
        const data = await s3.send(new PutObjectCommand(params));
        const location = `https://jetafx-video-storage.s3.amazonaws.com/${encodeURIComponent(
          file.name
        )}`;
        return location;
      } catch (err) {
        console.error(err);
      }
    }

    const uploadedLocations = [];
    for (const file of files) {
      const location = await uploadFile(file);
      if (location) {
        uploadedLocations.push(location);
      }
    }
    const uploadedData = {
      title: value.title,
      language: value.language,
      duration: value.duration,
      description: value.description,
      module_no: value.module_no,
      videos: uploadedLocations,
    };
    const data = await addVideo(uploadedData);
    if (data?.status == 201) {
      Swal.fire({
        icon: "success",
        title: "Video Added Successfully",
        text: "",
      });
      navigate("/dashboard/video");
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: data?.data?.message,
        text: "",
      });
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        Add Video
      </Typography>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          console.log(data, "datass");
          validationSchema
            .validate(data, { abortEarly: false })
            .then(() => {
              handleEventAdd(data);
              setSubmitting(false);
            })
            .catch((validationErrors) => {
              console.error("Validation Errors:", validationErrors);
              setSubmitting(false);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Div sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField1
                    name="title"
                    label="Title"
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                    value={values?.title}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FormTextField1
                    name="module_no"
                    type="number"
                    label="Module"
                    onChange={(e) => setFieldValue("module_no", e.target.value)}
                    value={values.module_no}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ListOptions1
                    name="language"
                    label="Language"
                    options={languageList}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField1
                    name="duration"
                    label="Duration"
                    onChange={(e) => setFieldValue("duration", e.target.value)}
                    value={values.duration}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormTextField1
                    multiline
                    // rows={2}
                    name="description"
                    label="Description"
                  />
                </Grid>
              </Grid>
            </Div>
            <Grid container rowSpacing={3} columnSpacing={3} marginTop={-1}>
              <Grid item xs={9}>
                <Typography variant="body1">Videos :-</Typography>
                <DropMultiImage setVideos={setFiles} videos={files} />
              </Grid>
            </Grid>{" "}
            <Div
              sx={{
                width: "93.5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/dashboard/video");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ width: "100px" }}
                loading={loading}
              >
                Save
              </LoadingButton>
            </Div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AddVideo;
