import Div from "@jumbo/shared/Div/Div";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SidebarMenu() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const active = {
    backgroundColor: "transparent", // Set transparent background to allow backgroundImage to show
    backgroundImage:
      "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    fontSize: "1rem",
    cursor: "pointer",
    padding: "8px 16px", // Adjust padding instead of 'p' and 'pl'
    fontFamily: "sans-serif",
  };

  const inactive = {
    "&:hover": {
      bgcolor: "gray",
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
    },
    fontSize: "1rem",
    cursor: "pointer",
    p: 1,
    pl: 2,
    fontFamily: "sans-serif",
  };
  const activeAccordion = {
    backgroundColor: "transparent", // Set transparent background to allow backgroundImage to show
    backgroundImage:
      "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    fontSize: "0.8rem",
    cursor: "pointer",
    p: 1,
    pl: 5,
  };
  const inactiveAccordion = {
    "&:hover": {
      bgcolor: "gray",
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
    },
    fontSize: "0.8rem",
    cursor: "pointer",
    p: 1,
    pl: 5,
  };

  return (
    <Div>
      {(permissions?.user_view === true ||
        permissions?.user_edit === true ||
        permissions?.user_create === true) && (
        <Div>
          <Typography
            sx={
              currentPath == "/dashboard/user" ||
              currentPath == "/dashboard/adduser" ||
              currentPath == "/dashboard/edituser"
                ? active
                : inactive
            }
            onClick={() => navigate("/dashboard/user")}
          >
            User Management
          </Typography>
        </Div>
      )}
      {(permissions?.role_view === true ||
        permissions?.role_edit === true ||
        permissions?.role_create === true) && (
        <Div>
          <Typography
            sx={
              currentPath == "/dashboard/roles" ||
              currentPath == "/dashboard/addrole" ||
              currentPath == "/dashboard/editrole"
                ? active
                : inactive
            }
            onClick={() => navigate("/dashboard/roles")}
          >
            Roles & Permissions
          </Typography>
        </Div>
      )}
      {(permissions?.client_view === true ||
        permissions?.client_edit === true ||
        permissions?.client_create === true) && (
        <Div>
          <Typography
            sx={
              currentPath == "/dashboard/client" ||
              currentPath == "/dashboard/addclient" ||
              currentPath == "/dashboard/editclient"
                ? active
                : inactive
            }
            onClick={() => navigate("/dashboard/client")}
          >
            Client Management
          </Typography>
        </Div>
      )}
      {(permissions?.video_view === true ||
        permissions?.video_edit === true ||
        permissions?.video_create === true) && (
        <Div>
          <Typography
            sx={
              currentPath == "/dashboard/video" ||
              currentPath == "/dashboard/addvideo" ||
              currentPath == "/dashboard/editvideo"
                ? active
                : inactive
            }
            onClick={() => navigate("/dashboard/video")}
          >
            Video Management
          </Typography>
        </Div>
      )}
      {(permissions?.career_view === true ||
        permissions?.career_edit === true ||
        permissions?.career_create === true) && (
        <Div>
          <Typography
            sx={
              currentPath == "/dashboard/career" ||
              currentPath == "/dashboard/addcareer" ||
              currentPath == "/dashboard/editcareer"
                ? active
                : inactive
            }
            onClick={() => navigate("/dashboard/career ")}
          >
            Career Management
          </Typography>
        </Div>
      )}

      {(permissions?.blog_view === true ||
        permissions?.blog_edit === true ||
        permissions?.blog_create === true) && (
        <Div>
          <Typography
            sx={
              currentPath == "/dashboard/blog" ||
              currentPath == "/dashboard/addblog" ||
              currentPath == "/dashboard/editblog"
                ? active
                : inactive
            }
            onClick={() => navigate("/dashboard/blog")}
          >
            Blog Management
          </Typography>
        </Div>
      )}
    </Div>
  );
}
