// import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { v4 as uuidv4 } from "uuid";
// import { Button, ImageList, ImageListItem } from "@mui/material";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// const DropVideo = ({ setVideos,videos }) => {
//     console.log(videos,'videos');
//   const [uploadedVideos, setUploadedVideos] = useState(videos ?videos :[]);

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "video/*",
//     onDrop: (acceptedFiles) => {
//       const newVideos = acceptedFiles.map((file) => ({
//         id: uuidv4(),
//         name: file.name.substring(0, file.name.lastIndexOf(".")),
//         preview: URL.createObjectURL(file),
//         file: file,
//       }));
//       setUploadedVideos((prevUploadedVideos) => [...prevUploadedVideos, ...newVideos]);
//       setVideos((prevVideos) => [...prevVideos, ...newVideos]);
//     },
//   });

//   const handleRemoveVideo = (idToRemove) => {
//     setUploadedVideos((prevUploadedVideos) =>
//       prevUploadedVideos.filter((video) => video.id !== idToRemove)
//     );
//     setVideos((prevVideos) => prevVideos.filter((video) => video.id !== idToRemove));
//   };

//   return (
//     <>
//       <div {...getRootProps({ className: "dropzone" })}>
//         <input {...getInputProps()} />
//         <Button size="small" variant="contained">
//           Select Videos
//         </Button>
//       </div>
//       <ImageList sx={{ width: "100%", maxHeight: 250 }} cols={4} rowHeight={110}>
//         {uploadedVideos.map((video) => (
//           <ImageListItem key={video.id} sx={{ position: "relative" }}>
//             <HighlightOffIcon
//               sx={{
//                 position: "absolute",
//                 top: 0,
//                 right: 0,
//                 cursor: "pointer",
//                 color: "red",
//                 zIndex: 1,
//                 backgroundColor: "rgba(255, 255, 255, 0.5)",
//               }}
//               onClick={() => handleRemoveVideo(video.id)}
//             />
//             <video
//               src={video.preview}
//               style={{ width: "100%", height: "100%", display: "block" }}
//               controls
//             />
//             <p>{video.name}</p>
//           </ImageListItem>
//         ))}
//       </ImageList>
//     </>
//   );
// };

// export default DropVideo;

// import { Button, ImageList, ImageListItem } from "@mui/material";
// import React from "react";
// import { useDropzone } from "react-dropzone";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// const DropMultiImage = ({ setVideos, videos }) => {
//   console.log(videos, "videos");
//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "video/*",
//     onDrop: (acceptedFiles) => {
//         setVideos((prevFiles) => [
//         ...prevFiles,
//         ...acceptedFiles.map((file) =>
//           Object.assign(file, {
//             preview: URL.createObjectURL(file),
//             id: Date.now() + Math.random(),
//           })
//         ),
//       ]);
//     },
//   });

//   const handleRemoveNewPhoto = (idToRemove) => {
//     setVideos((prevImages) => {
//       const updatedData = prevImages.filter((file) => file.id !== idToRemove);
//       return updatedData;
//     });
//   };

//   return (
//     <>
//       <div
//         {...getRootProps({ className: "dropzone" })}
//         style={{ marginTop: "10px", width: "120px" }}
//       >
//         <input {...getInputProps()} />
//         <Button size="small" variant="contained">
//           Select videos
//         </Button>
//       </div>
//       <ImageList
//         sx={{ width: "100%", maxHeight: 250 }}
//         cols={4}
//         rowHeight={110}
//       >
//         {videos?.map((file) => (
//           <ImageListItem key={file.id}>
//             <HighlightOffIcon
//               style={{
//                 position: "absolute",
//                 top: 5,
//                 right: 5,
//                 cursor: "pointer",
//                 color: "red",
//               }}
//               onClick={() => handleRemoveNewPhoto(file.id)}
//             />
//             <img
//               src={file.preview}
//               alt=""
//               style={{ width: "100%", height: "100%", display: "block" }}
//               loading="lazy"
//             />
//           </ImageListItem>
//         ))}
//       </ImageList>
//     </>
//   );
// };

// export default DropMultiImage;

import { Button, ImageList, ImageListItem } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const DropMultiImage = ({ setVideos, videos }) => {
  console.log(videos, "videos");
  const { getRootProps, getInputProps } = useDropzone({
    accept: "video/*",
    onDrop: (acceptedFiles) => {
      setVideos((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: Date.now() + Math.random(),
          })
        ),
      ]);
    },
  });

  const handleRemoveNewVideo = (idToRemove) => {
    setVideos((prevVideos) => {
      const updatedData = prevVideos.filter((video) => video.id !== idToRemove);
      return updatedData;
    });
  };

  return (
    <>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ marginTop: "10px", width: "120px" }}
      >
        <input {...getInputProps()} />
        <Button size="small" variant="contained">
          Select videos
        </Button>
      </div>
      <ImageList
        sx={{ width: "100%", maxHeight: 250 }}
        cols={4}
        rowHeight={110}
      >
        {videos?.map((video) => (
          <ImageListItem key={video.id}>
            <HighlightOffIcon
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
                color: "red",
                zIndex: 1,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
              onClick={() => handleRemoveNewVideo(video.id)}
            />
            <video
              src={video.preview}
              controls
              style={{ width: "100%", height: "100%", display: "block" }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default DropMultiImage;
