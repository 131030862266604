import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import ListOptions from "app/components/Dropdown/ListOptions";
import {
  codeList,
  genderList,
  outerDiv1,
} from "app/utils/constants/dropdowns.js";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUserDetailsAdmin } from "app/services/apis/updateUserDetailsAdmin";
import Swal from "sweetalert2";
import { addUser } from "app/services/apis/addUser";
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import ListOptions1 from "app/components/Dropdown/ListOptions1";
import { Axios } from "index";
import AllApis from "app/Apis";

export default function AddUser() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [roles, setRoles] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const { pathname } = useLocation();
  const data = state;

  const user = {
    first_name: data?.first_name ? data?.first_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    email_id: data?.email_id ? data?.email_id : "",
    gender: data?.gender ? data?.gender : "Select",
    age: data?.age ? data?.age : "",
    mobile_no: data?.mobile_no ? data?.mobile_no : "",
    country_code: data?.country_code ? data?.country_code : "+91",
    role_id: data?.role_id?._id ? data?.role_id?._id : "Select",
    status: data?.status == false || data?.status == true ? data?.status : true,
  };
  const validationSchema = yup.object({
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(
        /^[A-Za-z\s]+$/,
        "First Name must contain only alphabetic characters"
      ),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Last Name must contain only alphabetic characters"
      ),
    email_id: yup
      .string("Enter your Email ID")
      .email("Enter a valid Email ID")
      .required("Email is required"),
    gender: yup
      .string()
      .required("Gender is required")
      .test(
        "gender-not-select",
        "Please select a valid Gender",
        (value) => value !== "Select"
      ),
    age: yup
      .number()
      .typeError("Age must be a number")
      .required("Age is required"),
    mobile_no: yup
      .string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .length(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),

    role_id: yup
      .string()
      .required("Role is Required")
      .test(
        "",
        "Please select a valid Role",
        (value) => value !== "Select"
      ),
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    if (values?.role_id == "Select") {
      return Swal.fire({
        icon: "warning",
        title: "Please Select Role",
        text: "",
      });
    }
    if (pathname == "/dashboard/edituser") {
      const data = await updateUserDetailsAdmin({
        ...values,
        id: state?._id,
      });
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "User Edited Successfully",
          text: "",
        });
        navigate("/dashboard/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addUser(values);

      if (data?.status == 201) {
        Swal.fire({
          icon: "success",
          title: "User Added Successfully",
          text: "",
        });
        navigate("/dashboard/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(async () => {
    const roles = await Axios.get(`${AllApis?.dropdownList?.roles}`);
    setRoles(roles?.data?.result);
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/dashboard/adduser" ? "Add New User" : "Edit User"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
          
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                

                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={4}>
                    <FormTextField1 name="first_name" label="First Name" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextField1 name="last_name" label="Last Name" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextField1 name="email_id" label="Email" />
                  </Grid>
                  <Grid item xs={4}>
                    <ListOptions1
                      name="gender"
                      label="Gender"
                      options={genderList}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextField1 name="age" label="Age" />
                  </Grid>

                  <Grid item xs={4}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h5">Phone No*</Typography>
                      <Div sx={{ display: "flex", mt: -1 }}>
                        <ListOptions
                          options={codeList}
                          name={"country_code"}
                          sx={{ width: "80px" }}
                        />
                        <FormTextField1
                          name="mobile_no"
                          sx={{ width: "100%" }}
                        />
                      </Div>
                    </Div>
                  </Grid>

                  <Grid item xs={4}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h5">Role</Typography>
                      <Select
                        labelId="role_name"
                        name="role_id"
                        id="role_id"
                        value={values?.role_id}
                        onChange={(event) => {
                          const selectedRoleId = event.target.value;
                          const selectedRole = roles.find(
                            (role) => role._id === selectedRoleId
                          );
                          setFieldValue("role_id", selectedRoleId);
                        }}
                        sx={{
                          ".css-153xi1v-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                            { padding: 1.2 },
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {roles.map((item) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.role_name}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name={"role_id"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Grid>
                </Grid>
                <Div sx={{ mt: 5 }}>
                  <Typography variant="h5">Status</Typography>
                  <Switch
                    onChange={(e) => {
                      setFieldValue(
                        "status",
                        values?.status == true ? false : true
                      );
                    }}
                    defaultChecked={values?.status == true ? true : false}
                    sx={{
                      p: 0,
                      width: "70px",
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: values?.status === true ? "green" : "red",
                        width: "90%",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor:
                            values.status === true ? "green" : "red",
                        },
                    }}
                  />
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/user");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
