import Div from "@jumbo/shared/Div/Div";
import { Button, Grid, Typography } from "@mui/material";
import {
  jobTypeList,

} from "app/utils/constants/dropdowns.js";
import { Form, Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import FormTextField1 from "app/components/InputField/FormTextField1";
import ListOptions1 from "app/components/Dropdown/ListOptions1";
import { addCareer } from "app/services/apis/addCareer";
import { updateCareer } from "app/services/apis/updateCareer";

export default function AddCareer() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { pathname } = useLocation();
  const data = state;

  const client = {
    job_title: data?.job_title ? data?.job_title : "",
    job_type: data?.job_type ? data?.job_type : "Select",
    location: data?.location ? data?.location : "",
    address: data?.address ? data?.address : "",
    salary: data?.salary ? data?.salary : "",
  };
  const validationSchema = yup.object({
    job_title: yup.string("Enter Job Title").required("Job Title is required"),
    address: yup.string("Enter Address").required("Address is required"),
    location: yup.string("Enter Location").required("Location is required"),
    salary: yup.string("Enter Salary").required("Salary is required"),
    job_type: yup
      .string()
      .required("Job Type is required")
      .test(
        "Job Type not select",
        "Please select a valid Job Type",
        (value) => value !== "Select"
      ),
  });

  const onClientSave = async (values) => {
    setSubmitting(true);
    if (pathname == "/dashboard/editcareer") {
      const data = await updateCareer({
        ...values,
        id: state?._id,
      });
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Career Edited Successfully",
          text: "",
        });
        navigate("/dashboard/career");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addCareer(values);

      if (data?.status == 201) {
        Swal.fire({
          icon: "success",
          title: "Career Added Successfully",
          text: "",
        });
        navigate("/dashboard/career");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/dashboard/addcareer" ? "Add New Career" : "Edit Career"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={client}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={4}>
                    <FormTextField1 name="job_title" label="Job Title" />
                  </Grid>
                  <Grid item xs={4}>
                    <ListOptions1
                      name="job_type"
                      label="Job Type"
                      options={jobTypeList}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextField1 name="salary" label="Salery" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextField1 name="location" label="Location" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextField1 name="address" label="Address" />
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/client");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
