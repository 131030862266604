import {
  ALL_CLIENTS_FAIL,
  ALL_CLIENTS_REQUEST,
  ALL_CLIENTS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_CLIENT_FAIL,
  LOAD_CLIENT_REQUEST,
  LOAD_CLIENT_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
} from "app/utils/constants/clientConstants";

import axios from "axios";
import Cookies from "js-cookie";

export const login =
  (email_id, password, setSubmitting) => async (dispatch) => {
    try {
      setSubmitting(true);
      dispatch({ type: LOGIN_REQUEST });
      const config = {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/auth/signin`,
        { email_id, password },
        config
      );

      let client_details = data?.data?.result;
      let token = data?.data?.token;
      console.log(client_details, "client_details");

      dispatch({ type: LOGIN_SUCCESS, payload: client_details });
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem(
        "permissions",
        JSON.stringify(client_details?.role_id?.permissions)
      );
      sessionStorage.setItem(
        "role_name",
        JSON.stringify(client_details?.role_id?.role_name)
      );
      Cookies.set("token", token);
    } catch (error) {
      // console.log(error);
      dispatch({ type: LOGIN_FAIL, payload: error?.response?.data?.message });
      sessionStorage.setItem("isAuthenticated", false);
    } finally {
      setSubmitting(false);
    }
  };

export const loadClient = () => async (dispatch) => {
  try {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    dispatch({ type: LOAD_CLIENT_REQUEST });
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/profile/list-client-profile`,
      config
    );
    // console.log(data);
    dispatch({ type: LOAD_CLIENT_SUCCESS, payload: data.data.result });
  } catch (error) {
    dispatch({
      type: LOAD_CLIENT_FAIL,
      payload: error.response.data.description,
    });
  }
};

export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_SUCCESS });
    sessionStorage.setItem("isAuthenticated", false);
    sessionStorage.clear();
    localStorage.clear();
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL,
      payload: error,
    });
  }
};

export const getAllClients =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      dispatch({ type: ALL_CLIENTS_REQUEST });
      const body = {
        filters: {},
        searchFields: {
          string: ["first_name", "last_name", "gender"],
          numbers: ["age", "employee_id", "mobile_no", "created_by"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/client/list-client?${urlParams.toString()}`,
        body,
        config
      );

      console.log(data, "fgsgesg");
      dispatch({
        type: ALL_CLIENTS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CLIENTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
