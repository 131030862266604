export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_CAREER_REQUEST = "LOAD_CAREER_REQUEST";
export const LOAD_CAREER_SUCCESS = "LOAD_CAREER_SUCCESS";
export const LOAD_CAREER_FAIL = "LOAD_CAREER_FAIL";

export const ALL_CAREERS_REQUEST = "ALL_CAREERS_REQUEST";
export const ALL_CAREERS_SUCCESS = "ALL_CAREERS_SUCCESS";
export const ALL_CAREERS_FAIL = "ALL_CAREERS_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
